<template>
  <router-view />
</template>

<script>

import { getAuthToken, tokenCheck } from '@/libs/auth-helper'

export default {
  name: 'Main',
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    const token = getAuthToken()

    if (token) {
      next()
    } else {
      next(to.params.prefix + '/login')
    }
  }
}
</script>
